@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background-color: rgba(245, 158, 11, 0.7);
  color: #fefefe;
}

.prose .anchor {
  @apply invisible absolute no-underline;
  margin-left: -1em;
  padding-right: 0.5em;
  width: 80%;
  max-width: 700px;
  cursor: pointer;
}

.anchor:hover {
  @apply visible;
}

.prose a {
  @apply decoration-neutral-400 decoration-[0.1em] underline-offset-2 transition-all dark:decoration-neutral-600;
}

.prose .anchor:after {
  @apply text-neutral-300 dark:text-neutral-700;
  content: "#";
}

.prose *:hover>.anchor {
  @apply visible;
}

[data-rehype-pretty-code-fragment] code {
  @apply grid min-w-full break-words rounded-none border-0 bg-transparent p-0 text-sm text-black;
  counter-reset: line;
  box-decoration-break: clone;
}

[data-rehype-pretty-code-fragment] .line {
  @apply py-1;
}

[data-rehype-pretty-code-fragment] [data-line-numbers]>.line::before {
  counter-increment: line;
  content: counter(line);
  display: inline-block;
  width: 1rem;
  margin-right: 1rem;
  text-align: right;
  color: gray;
}

[data-rehype-pretty-code-fragment] .line--highlighted {
  @apply bg-slate-500 bg-opacity-10;
}

[data-rehype-pretty-code-fragment] .line-highlighted span {
  @apply relative;
}

[data-rehype-pretty-code-fragment] .word--highlighted {
  @apply rounded-md bg-slate-500 bg-opacity-10 p-1;
}

[data-rehype-pretty-code-title] {
  @apply rounded-t-lg border border-[#333333] bg-[#1c1c1c] px-4 py-3 font-mono text-xs font-medium text-neutral-200;
}

[data-rehype-pretty-code-title]+pre {
  @apply mt-0 rounded-t-none border-t-0;
}

@layer utilities {
  .shadow-button-light {
    box-shadow:
      0 0 #0000,
      0 0 #0000,
      inset 0px 1px 0px 0px #fff,
      0px 0px 0px 1px rgba(0, 0, 0, 0.06),
      0px 1px 0px 0px rgba(0, 0, 0, 0.08),
      0px 2px 2px 0px rgba(0, 0, 0, 0.04),
      0px 3px 3px 0px rgba(0, 0, 0, 0.02),
      0px 4px 4px 0px rgba(0, 0, 0, 0.01);
  }

  .shadow-button-dark {
    box-shadow:
      0 0 #0000,
      0 0 #0000,
      0px -1px 0px 0px hsla(0, 0%, 100%, 0.04),
      0px 0px 0px 1px hsla(0, 0%, 100%, 0.12),
      0px 0px 1px 0px rgba(0, 0, 0, 0.04),
      0px 2px 2px 0px rgba(0, 0, 0, 0.04),
      0px 4px 2px 0px rgba(0, 0, 0, 0.04),
      0px 6px 3px 0px rgba(0, 0, 0, 0.04);
  }
}

@layer components {
  .box-gen {
    @apply border border-white bg-zinc-50/70 bg-blend-luminosity outline outline-1 outline-offset-0 outline-zinc-200 backdrop-blur-75px dark:border-white/10 dark:bg-zinc-900/20 dark:outline-[#1a1a1a];
  }

  .title-primary {
    @apply text-4xl font-bold tracking-tight text-white dark:text-white sm:text-5xl;
  }

  .body-primary {
    @apply text-neutral-900 dark:text-white;
  }

  .body-secondary {
    @apply text-white dark:text-white/80;
  }
}

/* Additional Styles */
@layer base {
  :root {
    --hue-1: 286;
    --hue-2: 183;
    --hue-3: 191;
    --alpha-1: 0.271;
    --alpha-2: 0.951;
    --alpha-3: 0.351;
  }

  .scene {
    @apply h-screen w-screen absolute top-0 left-0 z-0 overflow-hidden;
  }

  .northen-lights {
    @apply absolute top-0 w-[200vmax] h-[100vmax] flex blur-[75px];
    left: 50%;
    transform: translate(-20%, -55%) rotate(30deg);
  }

  .northen-light {
    @apply flex-1;
    background-size: 100% 40vmax;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image: linear-gradient(0deg,
        transparent,
        hsla(var(--hue-1), 100%, 50%, var(--alpha-1)) 10%,
        transparent,
        hsla(var(--hue-2), 100%, 50%, var(--alpha-2)) 40%,
        transparent,
        hsla(var(--hue-3), 100%, 50%, var(--alpha-3)) 60%);
    animation: shift calc(var(--duration, 2s)) calc(var(--delay, 0s)) ease infinite;
    transform: translate(calc(var(--x, 0) * 2vmax), calc(var(--y, 0) * 2vmax));
  }
}

/* Add media query for responsiveness */
@media (max-width: 768px) {
  .northen-light {
    --up: calc(var(--y, 10) * 2vmax);
    --left: calc(var(--x, 2) * 2vmax);
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  height: 30px;
  width: 200px;
  transform: rotate(-30deg);
  background: 
    radial-gradient(farthest-side,#f03355 90%,#0000) left bottom/25px 25px no-repeat
    #ddd;
  animation:
    l17-0 2s infinite alternate linear,
    l17-1 2s infinite cubic-bezier(.75,0,1,.6);
}
@keyframes l17-0 {
    50% {transform:rotate(30deg)}
}
@keyframes l17-1 {
    50% {background-position: right bottom}
}



/* HTML: <div class="loader"></div> */
.loading{
  width: fit-content;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 30px;
  padding: 0 5px 8px 0;
  background: repeating-linear-gradient(90deg,currentColor 0 8%,#fff 0 10%) 200% 100%/200% 3px no-repeat;
  animation: l3 2s steps(6) infinite;
}
.loading:before {
  content:"Loading"
}
@keyframes l3 {to{background-position: 80% 100%}}


